import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import * as moment from 'moment-timezone';

// import * as moment from 'moment';
@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isTime = false;
  isZone = false;
  isAuto = false;
  isAutoClean = false;
  isSkipToday = true;
  isSkipTomorrow = true;
  interval = null;
  tripInterval = null;
  restrictedLimit = 200;
  currentDate; // = new Date().toISOString().split('T')[0];
  startPrice = 30;
  loadedCharacter: {};
  apiURL = 'https://api.americanlogistics.com/api/bolt/v1.0/trips/';
  postApiURL = `${this.apiURL}events/`;
  getApiURL = ''; // `${this.apiURL}open?top=50&offset=0&puState=CA&dueTimeAfter=2021-02-25T11:22:00%2B05:00&preferred=false&sortBy=duetime&sortOrder=ASC`;
  authHeader: any;
  authorization = ''; // 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IndpSEtLc1NkS2FTRkpCaHRYNUxfMm42WkNzNWFZY0M5TnNaelpUZXRKRFUifQ.eyJpc3MiOiJodHRwczovL2Nvc21vc3RlbmFudC5iMmNsb2dpbi5jb20vOTBjNWM5YTQtZDkyNy00ZDViLTliNWItNjhkZWI4NDU3Y2Y5L3YyLjAvIiwiZXhwIjoxNjE0MTY0OTc3LCJuYmYiOjE2MTQxNjEzNzcsImF1ZCI6ImZiNzA3MDRjLWJiYTEtNGY0My05NDI2LTk2YmI3ODkyYzlkNyIsInN1YiI6IjZiZjdlNmY4LTkwMWYtNDY0Ny05YTY4LWEzYTcxZTUwMzhkMyIsInJvbGVzIjpbIjE0Il0sInBlcm1pc3Npb25zIjpbIjIyIiwiMjMiLCIyNCIsIjI1IiwiMjYiXSwib3JnYW5pemF0aW9uIjoiQUxJWkFBUkEgTExDIiwidXNlcm5hbWUiOiJ6YWFyYWRhbWFuaUB5YWhvby5jb20iLCJwcm9ncmFtSWRzIjpbIjQ1IiwiNTAiLCI1MSIsIjkiXSwicHJvdmlkZXJJZCI6MzczLCJzdGF0ZSI6IkNBIiwicHJvdmlkZXJOYW1lIjoiQUxJWkFBUkEgTExDIiwic2VydmljZVJlbWluZGVyTWV0aG9kIjoiU01TIiwidXNlcklkIjo0NzY4LCJwcm92aWRlclRpZXIiOjMsInRpZCI6IjkwYzVjOWE0LWQ5MjctNGQ1Yi05YjViLTY4ZGViODQ1N2NmOSIsIm5vbmNlIjoiODdiYzg1N2QtMTVjYS00NTdmLWI5NjQtNGQyNDFkNGNkZmEzIiwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwiYXpwIjoiOTRkNzE4NzAtNjViZC00NTk0LWIyZjMtZjFhNTY3NGI1MDUyIiwidmVyIjoiMS4wIiwiaWF0IjoxNjE0MTYxMzc3fQ.SA_vFpG6ZRHb9lEHxWOsRWgTE7vVZIVOC25XCJ0hQhiY25Tq_7RiOW6A2_nUjORMtUMG-yaY5vo4GZ14D9Mamw8as-8kkb_S9HMnZ5mRvxW8luXXepi5tuf_U8mtCV0Pv7arBXkp7WEJgyLkXlIlzsoJg3K0WUfOjitNOi4oxmTa6DlBszKN7g8fS9JAPet9j33gXxyXdlnwD3SaqsmrdBYoY7tXFeDLAZTM9NuNoscuMcV15Zc4-gZlEmQ57P2BFIjSUX3WDP7ZurXwq3KKHabIVUFj6KEWYHYySHPkVITOpXyOuBv7qcwH_6Pvmjf4S3jnFIjXLKnkePyg99krBQ';
  selectedTrips = [];
  existingTrips = [];
  requestedTrips = 0;
  wcTrips = 0;
  drivers = [];
  citiesList = ['El Paso', 'Horizon City', 'Socorro', 'Fort Hancock'];
  trips = [];
  offset = 0;
  timeZone = 'America/Denver'; // GMT -7
  // payDriverTo = 100;
  // payDriverFrom = 12;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    //  this.consoleLogCustomMethod();
    console.log(moment().format());
    console.log(moment.tz(new Date(), this.timeZone).format());
    this.drivers = [
      // { "driverId": 6202, "providerId": 289, "driverName": "STEPHENS CARMEN", "firstName": "STEPHENS", "phoneNumber": "9153048503", "email": "hhtransportationtx@gmail.com", "eligibleToServiceTrip": true },
      // { "driverId": 18407, "providerId": 289, "driverName": "PORFIRIO BERNAL", "firstName": "PORFIRIO", "phoneNumber": "2544589378", "email": "BERNALPOF@GMAIL.COM", "eligibleToServiceTrip": true },
      // { "driverId": 20027, "providerId": 289, "driverName": "Pablo Vasquez", "firstName": "Pablo", "phoneNumber": "9152194980", "email": "Olivobk4033@gmail.clm", "eligibleToServiceTrip": true },
      { "driverId": 19950, "providerId": 289, "driverName": "Melissaa Jacobo", "firstName": "Melissaa", "phoneNumber": "9152194956", "email": "melissa.jacobo@helpinghandstransportation.com", "eligibleToServiceTrip": true },
      { "driverId": 6196, "providerId": 289, "driverName": "KARINA QUINONES", "firstName": "KARINA", "phoneNumber": "9152497875", "email": "hhtransportationtx@gmail.com", "eligibleToServiceTrip": true },
      { "driverId": 19956, "providerId": 289, "driverName": "CARMEN STEPHENS", "firstName": "CARMEN", "phoneNumber": "9152194937", "email": "HHTRANSPORTATIONTX@GMAIL.COM", "eligibleToServiceTrip": true }
    ]

    if (this.isAuto) {

    }
    if (this.isZone) {
      this.startPrice = 13.5;
      this.restrictedLimit = 55;
    }
    // this.getTrips();
  }
  consoleLogCustomMethod() {
    this.interval = setInterval(() => {
      console.clear();
    }, 20000);
  }

  getRandomDriver() {
    const driverIndex = Math.floor(Math.random() * this.drivers.length);
    const driver = this.drivers[driverIndex];
    return driver['driverId'];
  }
  skipToday() {
    this.isSkipToday = !this.isSkipToday;
  }
  skipTomorrow() {
    this.isSkipTomorrow = !this.isSkipTomorrow;
  }
  isAutoAction() {
    this.isAuto = !this.isAuto;
  }
  isAutoCleanAction() {
    this.isAutoClean = !this.isAutoClean;
    if (this.isAutoClean) {
      this.consoleLogCustomMethod();
    } else if (this.interval) {
      clearInterval(this.interval);
    }
  }
  isTimeAction() {
    this.isTime = !this.isTime;
    if (this.isTime) {
      this.tripInterval = setInterval(() => {
        console.clear();
        this.isAuto = true;
        this.offset = 0;
        this.getTrips();
      }, 30000);
    } else if (this.tripInterval) {
      clearInterval(this.tripInterval);
    }
  }
  getTrips() {
    let authHeader = new HttpHeaders({
      authorization: this.authorization
    });
    let options = { headers: authHeader };
    const dueTimeAfter = moment.tz(this.timeZone).format('YYYY-MM-DDTHH:mm:00.000');
    const dueTimeBefore = moment.tz(this.timeZone).add(1, 'weeks').startOf('day').format('YYYY-MM-DDTHH:mm:00.000');
    this.getApiURL = `${this.apiURL}open?payDriverFrom=${this.startPrice}&serviceLevel=ALS,AMBC2C,AMBD2D,AMBH2H,WCH2H,WCD2D,WCC2C,BLS,GUR&top=50&offset=${this.offset}&puState=TX&dueTimeAfterTripLocalTime=${dueTimeAfter}Z&preferred=false&dueTimeBeforeTripLocalTime=${dueTimeBefore}Z&sortBy=duetime&sortOrder=ASC`;
    this.http.get(this.getApiURL, options)
      .subscribe((trips: any) => {
        console.log('Total Trips --- ', trips);
        if (trips.length === 0) {
          this.offset = 0;
        } else {
          this.offset += trips.length;
        }
        this.selectedTrips = [];
        // this.trips = [];
        this.currentDate = moment.tz(this.timeZone).format('YYYY-MM-DD');
        trips.forEach(el => {
          console.log(`Current Date ${this.currentDate} -- Trip ${el.boltTripId} --- ${el.serviceLevel} --- Due Date: ${el.dueDateTime} --- ${el.pickupCity} --- ${this.citiesList.includes(el.pickupCity)} --- ${el.dropoffCity} --- ${this.citiesList.includes(el.dropoffCity)} --- $${Number(el['payDriver'] || 0)} --- ${Number(el.tripMiles)}mi`);
          if (this.citiesList.includes(el.pickupCity)) {
            if (el.dueDateTime) {
              // this.currentDate = moment.tz(this.timeZone).format('YYYY-MM-DD');
              let dateTime = el.dueDateTime.split('T')[0];
              if (this.isSkipToday) {
                if (this.currentDate === dateTime) {
                  console.log(`Skip Current Date ${this.currentDate} ---- Trip Date ${dateTime} --- is matched ---- `, this.currentDate === dateTime)
                  return false;
                }
              }

              if (this.isSkipTomorrow) {
                const tomorrow = moment(this.currentDate, ['YYYY-MM-DD']).add(1, 'days').format('YYYY-MM-DD');
                if (tomorrow === dateTime) {
                  console.log(`Skip Current Date ${this.currentDate} ---- Trip Date ${dateTime} ---- Tomorrow Date ${tomorrow} --- is matched ---- `, tomorrow === dateTime)
                  return false;
                }
              }

              if (el.dueDateTime.includes('23:47')) {
                console.log('LEG B Trip ')
              }

            }

            // if (this.citiesList.includes(el.pickupCity)) {
            el.matchTime = moment.tz(this.timeZone).format('MM/DD/YYYY HH:mm');
            console.log(`Match Time: ${el.matchTime} --- Matching Trip: ${el.boltTripId} --- ${el.serviceLevel} --- Due Date: ${el.dueDateTime} --- ${el.pickupCity} --- ${this.citiesList.includes(el.pickupCity)} --- ${el.dropoffCity} --- ${this.citiesList.includes(el.dropoffCity)} --- $${Number(el['payDriver'] || 0)} --- ${Number(el.tripMiles)}mi`);
            this.trips.push(el);
            this.requestTrips(el);
            return true;
          }
        });
        console.log('Trips matched count --- ', this.selectedTrips.length);
        if (this.isAuto) {
          if (this.selectedTrips.length > 0) {
            this.confirmTrips();
          } else if (!this.isTime) {
            this.getTrips();
          }
        }
      },
        err => {
          console.log('HTTP Error --- ', err)
          this.offset = 0;
          if (this.isAuto && !this.isTime) {
            this.getTrips();
          }
        });
  }

  confirmTrips() {
    console.log('Request Matched and Send to Server --- ', this.selectedTrips.length);
    forkJoin(this.selectedTrips).subscribe(results => {
      console.log('Proceed --- ');
      if (this.isAuto) {
        this.getTrips();
      }
    }, err => {
      console.log('HTTP Error --- ', err)
      this.offset = 0;
      // this.getTrips();
      if (this.isAuto && !this.isTime) {
        this.getTrips();
      }
    });
    console.log('Re Fetch Trips from server');
  }
  generateRequest(params) {
    // return false;
    let authHeader = new HttpHeaders({
      authorization: this.authorization,
      'Content-Type': 'application/json'
    });
    let options = { headers: authHeader };
    return this.http.post(this.postApiURL, params, options);

  }
  requestTrips(el) {
    // return;
    if (this.existingTrips.length && this.existingTrips.includes(el.boltTripId)) {
      console.log(`Already Exist TripId ${el.boltTripId} `);
      return false;
    }
    if (this.requestedTrips >= this.restrictedLimit) {
      console.log('Requested Limit Exceeded from limit ---- ', this.selectedTrips, this.requestedTrips, this.restrictedLimit);
      return false;
    }
    let driverId = this.getRandomDriver();
    let tripParams = {
      "boltTripId": el.boltTripId,
      "eventType": "confirm",
      "time": moment.tz(this.timeZone).toISOString(),
      "driverId": driverId
    };
    this.selectedTrips.push(this.generateRequest(JSON.stringify(tripParams)));
    this.requestedTrips += 1;
    this.existingTrips.push(el.boltTripId);
    console.log(`Requested Trip:  ${el.boltTripId} --- ${el.serviceLevel} --- Due Date: ${el.dueDateTime} --- ${el.pickupCity} --- ${this.citiesList.includes(el.pickupCity)} --- ${el.dropoffCity} --- ${this.citiesList.includes(el.dropoffCity)} --- $${Number(el['payDriver'] || 0)} --- ${Number(el.tripMiles)}mi`);

    el.associatedPassengerTrip.forEach(boltTripId => {
      tripParams.boltTripId = boltTripId;
      this.selectedTrips.push(this.generateRequest(JSON.stringify(tripParams)));
      this.requestedTrips += 1;
      this.existingTrips.push(boltTripId);
      console.log(`Associated Trip: ${boltTripId} --- ${el.boltTripId} --- ${el.serviceLevel} --- Due Date: ${el.dueDateTime} --- ${el.pickupCity} --- ${this.citiesList.includes(el.pickupCity)} --- ${el.dropoffCity} --- ${this.citiesList.includes(el.dropoffCity)} --- $${Number(el['payDriver'] || 0)} --- ${Number(el.tripMiles)}mi`);
    });
  }
  saveFile() {
    const payload = [[
      'TRIPID', 'DOS', 'MILES'
    ]];
    this.trips.forEach(t => {
      let x = [t.boltTripId, t.dueDateTime, t.tripMiles];
      payload.push(x);
    })
    let filename = moment.tz(this.timeZone).format('YYYY-MM-DD')
    this.exportToCsv(`hht-${filename}.csv`, payload)
  }
  exportToCsv(filename, rows) {
    var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null || row[j] === undefined || row[j] === "" ? 'n/a' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        };
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"';
        if (j > 0)
          finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
  }
}
